import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './firebase'; // Import firebase first
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import baseTheme from './styles/theme';
// Import AuthProvider directly instead of lazy loading it
import { AuthProvider } from './contexts/AuthContext';

// Lazy load App component
const App = lazy(() => import('./App'));

// Loading component for suspense fallback
const LoadingFallback = () => {
  return (
    <div className="app-loader">
      <div className="loader-circle"></div>
      <p>Loading application...</p>
    </div>
  );
};

// Initialize performance monitoring
if (process.env.NODE_ENV === 'production') {
  // Add web vitals reporting to analytics in production
  reportWebVitals(metric => {
    // Simple console output for now, but could be sent to analytics service
    console.log(metric);
    
    // Example for sending to analytics
    if (window.gtag) {
      window.gtag('event', 'web_vitals', {
        metric_name: metric.name,
        metric_value: metric.value,
        metric_delta: metric.delta,
        metric_id: metric.id,
      });
    }
  });
}

// Preload critical assets
const preloadCriticalAssets = () => {
  if ('requestIdleCallback' in window) {
    window.requestIdleCallback(() => {
      // Preload routes during idle time
      import('./features/dashboard/components/Dashboard');
      
      // Preload key images that will be needed
      const preloadImages = [
        // Add paths to critical images here
        // '/logo.png',
        // '/background.jpg',
      ];
      
      preloadImages.forEach(imageUrl => {
        const link = document.createElement('link');
        link.rel = 'preload';
        link.as = 'image';
        link.href = imageUrl;
        document.head.appendChild(link);
      });
    });
  }
};

// Render app with proper error handling
const renderApp = () => {
  try {
    console.log('Initializing React application...');
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      // Disable StrictMode in production to prevent double rendering
      process.env.NODE_ENV === 'development' ? (
        <React.StrictMode>
          <ThemeProvider theme={baseTheme}>
            <CssBaseline />
            <AuthProvider>
              <Suspense fallback={<LoadingFallback />}>
                <>
                  <App />
                </>
              </Suspense>
            </AuthProvider>
          </ThemeProvider>
        </React.StrictMode>
      ) : (
        <ThemeProvider theme={baseTheme}>
          <CssBaseline />
          <AuthProvider>
            <Suspense fallback={<LoadingFallback />}>
              <>
                <App />
              </>
            </Suspense>
          </AuthProvider>
        </ThemeProvider>
      )
    );
    
    // Preload assets after initial render
    preloadCriticalAssets();
  } catch (error) {
    console.error('Error rendering application:', error);
    // Show a user-friendly error message
    document.getElementById('root').innerHTML = `
      <div style="padding: 20px; text-align: center;">
        <h2>Something went wrong</h2>
        <p>We're having trouble loading the application. Please refresh the page to try again.</p>
        <button onclick="window.location.reload()">Refresh</button>
      </div>
    `;
  }
};

// Start rendering
renderApp();
