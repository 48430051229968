// Import only the core functions needed for initial app load
import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, initializeFirestore, CACHE_SIZE_UNLIMITED, persistentLocalCache, persistentMultipleTabManager } from 'firebase/firestore';
import { getFunctions as getFirebaseFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { httpsCallable } from 'firebase/functions';

// Debug log
console.log('Initializing Firebase configuration...');

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "tradie-d0c2c.firebaseapp.com",
  databaseURL: "https://tradie-d0c2c.firebaseio.com",
  projectId: "tradie-d0c2c",
  storageBucket: "tradie-d0c2c.appspot.com",
  messagingSenderId: "1077927173715",
  appId: "1:1077927173715:web:0b744026c91bd72b9670a6",
  measurementId: "G-Z8BDT7E42M"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize auth service (needed for initial render)
const auth = getAuth(app);

// Initialize Firestore with optimized settings for reduced reads and persistence
let db;
try {
  // Use modern persistent cache approach
  db = initializeFirestore(app, {
    ignoreUndefinedProperties: true,
    localCache: persistentLocalCache({
      tabManager: persistentMultipleTabManager(),
      cacheSizeBytes: CACHE_SIZE_UNLIMITED
    })
  });
  
  console.log('Firestore persistence enabled successfully via modern cache configuration');
} catch (err) {
  console.warn('Error initializing Firestore with persistence:', err);
  // Fallback to standard initialization without persistence
  db = getFirestore(app);
  console.log('Fallback to standard Firestore initialization without persistence');
}

// Initialize Functions immediately to fix import issues
const functions = getFirebaseFunctions(app, 'us-central1');
// Connect to emulator in development
if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_USE_EMULATORS === 'true') {
  console.log('Connecting to Firebase Emulators...');
  connectFunctionsEmulator(functions, "localhost", 5001);
}

// Log functions initialization for debugging
console.log('[Functions] Initialized Firebase Functions with region: us-central1');
console.log('[Functions] Current hostname:', window.location.hostname);
console.log('[Functions] Current origin:', window.location.origin);

// Lazy-load other Firebase services (Storage and Functions)
let _storage = null;
let _functions = functions; // Use the already initialized functions

// Storage - loaded on demand
export const getStorage = async () => {
  if (!_storage) {
    const { getStorage: getStorageFunc } = await import('firebase/storage');
    
    // Explicitly set the storage bucket to match the configuration
    _storage = getStorageFunc(app, "gs://tradie-d0c2c.firebasestorage.app");
    
    // More detailed logging for debugging CORS issues
    console.log(`[DEBUG] Firebase Storage initialization details:`);
    console.log(`[DEBUG] - Storage bucket: ${firebaseConfig.storageBucket}`);
    console.log(`[DEBUG] - Public URL format: https://firebasestorage.googleapis.com/v0/b/${firebaseConfig.storageBucket}/o/...`);
    console.log(`[DEBUG] - Origin for CORS: ${window.location.origin}`);
    
    // Log more network details that might be relevant for CORS
    console.log(`[DEBUG] - Current origin: ${window.location.origin}`);
    console.log(`[DEBUG] - Current host: ${window.location.host}`);
    console.log(`[DEBUG] - Current protocol: ${window.location.protocol}`);
    console.log(`[DEBUG] - User agent: ${navigator.userAgent}`);
    
    // Test if the bucket exists and is accessible
    try {
      const { ref, listAll } = await import('firebase/storage');
      const rootRef = ref(_storage, '');
      console.log(`[DEBUG] - Testing storage access with ref path: ${rootRef.fullPath}`);
      
      // Try to list root contents as a basic connectivity test
      listAll(rootRef)
        .then(result => {
          console.log(`[DEBUG] - Storage connectivity test successful. Found ${result.items.length} items`);
        })
        .catch(error => {
          console.error(`[DEBUG] - Storage connectivity test failed:`, error);
          console.log(`[DEBUG] - Storage error code: ${error.code}, message: ${error.message}`);
          if (error.message && error.message.includes('CORS')) {
            console.error(`[DEBUG] - CORS issue detected during initial storage access`);
            console.log(`[DEBUG] - CORS errors usually indicate one of the following issues:`);
            console.log(`[DEBUG]   1. CORS not configured for your origin (${window.location.origin})`);
            console.log(`[DEBUG]   2. Firebase Storage security rules blocking the request`);
            console.log(`[DEBUG]   3. Network blocking or modification of cross-origin requests`);
          }
        });
    } catch (error) {
      console.warn('[DEBUG] - Error testing storage access:', error);
    }
  }
  return _storage;
};

// Creates a new reference with specific CORS workarounds
export const getStorageWithCORS = async () => {
  if (!_storage) {
    await getStorage();
  }
  return _storage;
};

// Functions - loaded on demand (now returns the already initialized functions)
export const getFunctions = async () => {
  return _functions;
};

// Debug log
console.log('Firebase services initialized successfully');

// Add simple event logging for analytics
export const logEvent = (eventName, eventParams = {}) => {
  try {
    if (typeof window !== 'undefined' && window.location.hostname === 'localhost') {
      console.log(`[Analytics] Event logged (development): ${eventName}`, eventParams);
      return;
    }
    
    console.log(`[Analytics] Event logged: ${eventName}`, eventParams);
  } catch (error) {
    console.warn(`Error logging event ${eventName}:`, error);
  }
};

/**
 * Creates an authenticated function call that ensures a fresh auth token is used
 * @param {string} functionName - The name of the Firebase function to call
 * @returns {Function} A wrapped function that handles authentication and token refreshing
 */
export const createAuthenticatedFunctionCall = (functionName) => {
  return async (data = {}) => {
    console.log(`[Firebase] Creating authenticated call to function: ${functionName}`);
    
    if (!auth.currentUser) {
      console.error(`[Firebase] No authenticated user for function call: ${functionName}`);
      throw new Error('Authentication required. Please log in and try again.');
    }
    
    try {
      // Force refresh the token to ensure it's valid
      const token = await auth.currentUser.getIdToken(true);
      console.log(`[Firebase] Successfully refreshed auth token for function: ${functionName}`);
      
      // Create the function call with the fresh token
      const functionCall = httpsCallable(functions, functionName);
      
      // Make the actual call
      console.log(`[Firebase] Calling function: ${functionName}`);
      const result = await functionCall(data);
      
      return result.data;
    } catch (error) {
      console.error(`[Firebase] Error calling function ${functionName}:`, error);
      
      // Check for authentication errors specifically
      if (error.code === 'unauthenticated' || error.code === 'permission-denied' || 
          (error.message && (error.message.includes('auth') || error.message.includes('permission')))) {
        console.error('[Firebase] Authentication error in function call');
        throw new Error('Authentication error. Please try logging out and back in.');
      }
      
      throw error;
    }
  };
};

// Export auth, app, db, and functions for immediate use
export { auth, app, db, functions }; 