import React, { useContext, useState, useEffect, createContext, useRef, useCallback } from 'react';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail
} from 'firebase/auth';
import { auth, db } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';

// Debug log
console.log('Initializing AuthContext...');

// Add local storage keys
const USER_CACHE_KEY = 'cached_user';
const USER_CACHE_TIMESTAMP_KEY = 'cached_user_timestamp';
const USER_CACHE_TTL = 15 * 60 * 1000; // 15 minutes

const AuthContext = createContext({});

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState(null);
  const [authReady, setAuthReady] = useState(false);
  
  // Add ref to track if we've logged cache usage
  const cacheLoggedRef = useRef(false);
  
  // Add function to notify when auth is fully initialized
  const ensureAuthInitialized = useCallback(() => {
    return new Promise((resolve) => {
      if (!loading) {
        resolve(user);
      } else {
        // Check state every 100ms until auth is ready
        const checkInterval = setInterval(() => {
          if (!loading) {
            clearInterval(checkInterval);
            resolve(user);
          }
        }, 100);
        
        // Set a timeout to resolve anyway after 5 seconds
        setTimeout(() => {
          clearInterval(checkInterval);
          resolve(user);
        }, 5000);
      }
    });
  }, [loading, user]);

  // Debug log
  console.log('Setting up auth state listener...');

  useEffect(() => {
    console.debug("AuthContext: Setting up auth state listener...");
    
    // Check for cached user first
    try {
      const cachedUserString = localStorage.getItem(USER_CACHE_KEY);
      const cachedTimestamp = localStorage.getItem(USER_CACHE_TIMESTAMP_KEY);
      
      if (cachedUserString && cachedTimestamp) {
        const cachedUser = JSON.parse(cachedUserString);
        const timestamp = parseInt(cachedTimestamp, 10);
        
        // If cache is still valid, use it immediately
        if (Date.now() - timestamp < USER_CACHE_TTL) {
          // Only log once per context lifecycle
          if (!cacheLoggedRef.current) {
            console.log('Using cached user data');
            cacheLoggedRef.current = true;
          }
          setUser(cachedUser);
          setLoading(false);
          setAuthReady(true);
        }
      }
    } catch (error) {
      console.warn('Error reading cached user:', error);
    }
    
    try {
      // Use onAuthStateChanged directly instead of setupAuthObserver
      const unsubscribe = onAuthStateChanged(auth, (authUser) => {
        if (authUser) {
          // Create a clean user object
          const userObj = {
            uid: authUser.uid,
            email: authUser.email,
            displayName: authUser.displayName,
            photoURL: authUser.photoURL,
            emailVerified: authUser.emailVerified,
          };
          
          setUser(userObj);
          
          // Cache user data
          try {
            localStorage.setItem(USER_CACHE_KEY, JSON.stringify(userObj));
            localStorage.setItem(USER_CACHE_TIMESTAMP_KEY, Date.now().toString());
          } catch (cacheError) {
            console.warn('Error caching user data:', cacheError);
          }
        } else {
          setUser(null);
          setUserRole(null);
          
          // Clear cached user
          try {
            localStorage.removeItem(USER_CACHE_KEY);
            localStorage.removeItem(USER_CACHE_TIMESTAMP_KEY);
          } catch (cacheError) {
            console.warn('Error clearing cached user data:', cacheError);
          }
        }
        
        setLoading(false);
        setAuthReady(true);
      });
      
      return unsubscribe;
    } catch (error) {
      console.error("Error setting up auth state listener:", error);
      setLoading(false);
      setAuthReady(true);
      return () => {};
    }
  }, []);

  const login = async (email, password) => {
    console.log('Attempting login...');
    try {
      return await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  };

  const resetPassword = async (email) => {
    console.log('Sending password reset email...');
    try {
      await sendPasswordResetEmail(auth, email);
      return { success: true };
    } catch (error) {
      console.error("Password reset error:", error);
      throw error;
    }
  };

  const register = async (email, password) => {
    console.log('Attempting registration...');
    try {
      return await createUserWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error("Registration error:", error);
      throw error;
    }
  };

  const loginWithGoogle = async () => {
    console.log('Attempting Google login...');
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      
      // Check if this is a new user
      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);
      const isNewUser = !userDoc.exists();
      
      // If it's a new user, create a minimal user profile
      if (isNewUser) {
        await setDoc(userDocRef, {
          email: user.email,
          displayName: user.displayName || '',
          photoURL: user.photoURL || '',
          createdAt: new Date(),
          lastLogin: new Date()
        });
        console.log('Created new user profile for Google sign-in');
      } else {
        // Update last login
        await setDoc(userDocRef, { lastLogin: new Date() }, { merge: true });
      }
      
      return { user, isNewUser };
    } catch (error) {
      console.error("Google login error:", error);
      throw error;
    }
  };

  const logout = async () => {
    console.log('Attempting logout...');
    try {
      // Clear user cache on logout
      localStorage.removeItem(USER_CACHE_KEY);
      localStorage.removeItem(USER_CACHE_TIMESTAMP_KEY);
      return await signOut(auth);
    } catch (error) {
      console.error("Logout error:", error);
      throw error;
    }
  };

  const value = {
    user,
    login,
    register,
    logout,
    loginWithGoogle,
    resetPassword,
    userRole,
    loading,
    authReady,
    ensureAuthInitialized
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
} 